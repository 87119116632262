import { template as template_d475ff8e487049b9b3ca4bd1a71c3405 } from "@ember/template-compiler";
const FKLabel = template_d475ff8e487049b9b3ca4bd1a71c3405(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
