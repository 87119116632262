import { template as template_44acfa673d424b80bf28c7d962d208f8 } from "@ember/template-compiler";
const WelcomeHeader = template_44acfa673d424b80bf28c7d962d208f8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
