import { template as template_9ed6ec17b0ec4f4f852bdb175212738c } from "@ember/template-compiler";
const FKControlMenuContainer = template_9ed6ec17b0ec4f4f852bdb175212738c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
